<template>
  <div class="mw8 center">
    <h1>
      Request Submitted
    </h1>
    <p>
      An IAJESdb administrator must approve your request. When your request is
      approved, you will be able to log in and complete your user profile.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
